'use client'
import React, {useEffect, useRef} from "react";
import {faqSection, initResponsiveTables} from "@/app/utils/wp-utils";
import {setupSidebarIntendedItemsOnScroll} from "@/app/utils/advertizing-block-utils";
import PageSidebar from "@/app/components/auctor/page/PageSidebar";
import {setupTOCReadFlow, initTOC} from "@/app/utils/toc";

export default function WPPageContent({wpContentHTML, showInnerTitle, title, hasSidebar, sidebarRight}: {
    wpContentHTML: any
    showInnerTitle: boolean
    title?: string
    hasSidebar?: boolean
    sidebarRight?: boolean
}) {
    const isInit = useRef(false)

    useEffect(() => {
        if (!isInit.current) {
            setupTOCReadFlow();
            faqSection();
            if (hasSidebar) {
                setupSidebarIntendedItemsOnScroll();
                initTOC();
            }
            // only init responsive tables on md and lower devices
            const isDeviceLowerThanMD = window.matchMedia('(max-width: 992px)').matches
            if (isDeviceLowerThanMD) {
                // TODO: try to render those tables server side
                initResponsiveTables();
            }
            isInit.current = true
        }
    }, []);
    return (
        <div
            className={`flex flex-col lg:flex-row pt-10 gap-4 px-4 mx-auto w-full lg:max-w-[1100px]
                ${hasSidebar ? ' justify-center md:justify-between' : ' justify-center'}`}
        >

            {hasSidebar && !sidebarRight &&
                <PageSidebar hasTOC />
            }

            <div className={`bg-white w-full${hasSidebar ? ' md:max-w-[692px]' : ''}`}>
                {showInnerTitle && title &&
                    <div className={"rm-page-content"}>
                        <h2 dangerouslySetInnerHTML={{'__html': title}} />
                    </div>
                }

                <div
                    className={'post-content'}
                    dangerouslySetInnerHTML={{__html: wpContentHTML ?? ''}}
                />
            </div>

            {hasSidebar && sidebarRight &&
                <PageSidebar hasTOC />
            }
        </div>
    )
}
