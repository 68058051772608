import React, {useState, useEffect, ChangeEvent, useCallback, ChangeEventHandler, Ref, useRef} from 'react';
import {useFormContext} from "react-hook-form";
import dynamic from "next/dynamic";

interface TextInputProps {
    value: string | number | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    fieldName?: string;
    required?: boolean;
    debounce?: number | null;
    placeholder?: string;
    validation?: boolean;
    inputRef?: Ref<any>;
    className?: string;
    mask?: string;
}

export default function TextInput({
    value,
    onChange,
    fieldName,
    required,
    debounce,
    placeholder,
    validation = undefined,
    inputRef,
    className = '',
    mask,
}: TextInputProps) {
    const [internalValue, setInternalValue] = useState<string>(String(value));
    useEffect(() => {
        setInternalValue(String(value));
    }, [value]);

    const debouncedOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value;
            setInternalValue(rawValue);

            const handler = setTimeout(() => {
                onChange(e);
            }, debounce || 0);

            return () => {
                clearTimeout(handler);
            };
        },
        [onChange, debounce, value]
    );

    const inputClassName = `flex-grow h-full px-4 py-2 outline-none ${className}`
    return (
        <>
            {validation
                ? <TextInputWithValidation
                    value={internalValue}
                    onChange={onChange}
                    internalValue={internalValue}
                    debouncedOnChange={debouncedOnChange}
                    fieldName={fieldName}
                    validation={validation}
                    required={required}
                    placeholder={placeholder}
                    inputRef={inputRef}
                    mask={mask}
                    className={inputClassName}
                />
                :<input
                ref={inputRef}
                type="text"
                value={internalValue}
                onChange={debouncedOnChange}
                name={fieldName}
                required={required}
                className={inputClassName}
                placeholder={placeholder}
            />}
        </>
    );
}

function TextInputWithValidation(props: TextInputProps & { internalValue: string, debouncedOnChange: ChangeEventHandler }) {
    const { setValue, register, formState: { errors }} = useFormContext()

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(props.fieldName as string, e.target.value)
        props.debouncedOnChange(e)
    }

    return <input
            type="text"
            value={props.internalValue}
            required={props.required}
            className={props.className}
            placeholder={props.placeholder}
            {...register(props.fieldName as string)}
            onChange={(e) => handleOnChange(e)}
        />
}
