import {ChangeEvent, Ref, useEffect, useRef, useState} from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>{
    fieldName: string;
    required?: boolean;
}

interface DateInputProps extends InputProps {
    value: string | number | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    debounce?: number | null;
    placeholder?: string;
    validation?: boolean;
    ref?: Ref<any>;
}

export default function DateInput(props: DateInputProps) {
    const ref = useRef(null)

    return <input
        ref={ref}
        // onClick={() => ref?.current?.showPicker?.()}
        className={"[&::-webkit-calendar-picker-indicator]:opacity-0"}
        type={"date"}
        value={props.value}
        min={new Date().toISOString().split('T')[0]}
        onChange={(e) => props.onChange(e)}
    ></input>
}
