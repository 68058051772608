import Select, {components as selectComponents} from "react-select";

function Menu(props: any) {
    return <selectComponents.Menu {...props} className={'absolute bg-white w-full left-0 rounded-b border border-blue-300 '}>
        {props.options.map((option: any) => <MenuOption onClick={() => {
            props.setValue(option, 'set-value')
        }} key={option.label} option={option} />)}
    </selectComponents.Menu>
}

function MenuOption({ option, onClick }: any) {
    return <div onClick={() => onClick()} className={"px-4 py-2 cursor-pointer hover:bg-blue-600 hover:text-white"}>
        {option.label}
    </div>
}

export default function SelectInput (props: any) {
    const { options } = props.inputProps
    return <Select
        placeholder={"Selectionnez..."}
        className={"w-full static"}
        unstyled={true}
        hideSelectedOptions={true}
        components={{
            Menu,
        }}
        noOptionsMessage={() => 'Aucune option disponible'}
        value={options.find((option: any) => option.value === props.value)}
        getOptionValue={(option) => option.value}
        isSearchable={false}
        options={props.inputProps.options}
        onChange={(e) => props.onChange(e)}
    ></Select>
}
