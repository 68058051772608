interface ViewStatus {
    [key: string]: boolean;
}

let wasElementAfterView: ViewStatus = {};
let wasElementBeforeView: ViewStatus = {};

const handleElementComingInsight = (element: Element): void => {
    hideAdSidebarMatchingDupe(element);
    showPreviousSidebarDupeIfExist(element);
};

const handleElementGoingOutOfSight = (element: Element): void => {
    showAdSidebarMatchingDupe(element);
};

interface IntersectionItem extends IntersectionObserverEntry {
    target: Element;
}

const handleSidebarIntendedItemIntersect = (items: IntersectionItem[]): void => {
    items.forEach((item) => {
        const isElementAfterView = item.boundingClientRect.bottom > item.rootBounds!.bottom;
        const isElementBeforeView = item.boundingClientRect.top < item.rootBounds!.top;
        const itemDupeID = item.target.getAttribute('data-sidebar-duplicate-id')!;

        if (item.isIntersecting) { // element is intersecting with screen boundaries
            if (item.intersectionRatio >= 0.75) {
                // Element is shown by scroll
                if (wasElementBeforeView[itemDupeID]) {
                    // Reverse scroll -> bottom to top
                    handleElementComingInsight(item.target);
                } else if (wasElementAfterView[itemDupeID]) {
                    // Normal scroll -> top to bottom
                }
            }
        } else {
            if (item.intersectionRatio === 0.0) {
                // Element is hidden by scroll
                if (isElementAfterView) {
                    // Reverse scroll -> Hidden on bottom
                } else if (isElementBeforeView) {
                    // Normal scroll -> Hidden on top
                    handleElementGoingOutOfSight(item.target);
                }
            }
        }

        wasElementAfterView[itemDupeID] = isElementAfterView;
        wasElementBeforeView[itemDupeID] = isElementBeforeView;
    });
};

const showPreviousSidebarDupeIfExist = (element: Element): void => {
    const dupeID = element.getAttribute('data-sidebar-duplicate-id')!;
    const idParts = dupeID.split('-');
    const adNumber = parseInt(idParts.pop()!, 10);

    const previousId = `#${idParts.join('-')}-${adNumber - 1}`;

    const previousDupe = document.querySelector(previousId) as HTMLElement | null;

    if (previousDupe) {
        showAdInSidebar(previousDupe);
    }
};

const showAdSidebarMatchingDupe = (element: Element): void => {
    const dupeID = element.getAttribute('data-sidebar-duplicate-id')!;
    const sidebarDupe = document.querySelector(`#${dupeID}`) as HTMLElement | null;

    if (sidebarDupe) {
        showAdInSidebar(sidebarDupe);
    }
};

const hideAdSidebarMatchingDupe = (element: Element): void => {
    const dupeID = element.getAttribute('data-sidebar-duplicate-id')!;
    const sidebarDupe = document.querySelector(`#${dupeID}`) as HTMLElement | null;

    if (sidebarDupe) {
        hideSidebarAd(sidebarDupe);
    }
};

const showAdInSidebar = (element: HTMLElement): void => {
    clearSidebarAds();
    displayAdAsCurrent(element);
};

const clearSidebarAds = (): void => {
    const sidebarItems = document.querySelectorAll('.advertising-block-sidebar-item:not(.fix-in-sidebar)') as NodeListOf<HTMLElement>;

    sidebarItems.forEach((item) => {
        hideSidebarAd(item);
    });
};

const displayAdAsCurrent = (element: HTMLElement): void => {
    if (element.classList.contains('hidden')) {
        element.classList.remove('hidden');
    }

    if (!element.classList.contains('current-item')) {
        element.classList.add('current-item');
    }
};

const hideSidebarAd = (element: HTMLElement): void => {
    if (!element.classList.contains('hidden')) {
        element.classList.add('hidden');
    }

    if (element.classList.contains('current-item')) {
        element.classList.remove('current-item');
    }
};

export const setupSidebarIntendedItemsOnScroll = (): void => {
    const sidebarIntendedItems = document.querySelectorAll('.put-in-sidebar-on-scroll:not(.fix-in-sidebar):not([id^="sidebar-duplicate-"])') as NodeListOf<HTMLElement>;

    const sidebarFixedItems = document.querySelectorAll('.fix-in-sidebar:not([id^="sidebar-duplicate-"])') as NodeListOf<HTMLElement>;

    const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: [0.0, 0.75],
    };

    const observer = new IntersectionObserver(handleSidebarIntendedItemIntersect, observerOptions);

    const createSidebarDuplicate = (element: HTMLElement, dupeNumber: number): HTMLElement => {
        let sidebarDuplicate = element.cloneNode(true) as HTMLElement;

        const dupeID = `sidebar-duplicate-${dupeNumber}`;
        element.setAttribute('data-sidebar-duplicate-id', dupeID);
        sidebarDuplicate.setAttribute('id', dupeID);
        sidebarDuplicate.classList.add('advertising-block-sidebar-item');

        return sidebarDuplicate;
    };

    sidebarIntendedItems.forEach((element, index) => {
        // here we're duplicating element and moving them into the
        // hidden sidebar ad container in order to display them easily later on
        const sidebarDuplicate = createSidebarDuplicate(element, index);
        sidebarDuplicate.classList.add('hidden');

        const containerNode = document.querySelector('#sidebar-advertblock-container') as HTMLElement;
        containerNode.appendChild(sidebarDuplicate);

        observer.observe(element);
    });

    sidebarFixedItems.forEach((element, index) => {
        const sidebarDuplicate = createSidebarDuplicate(element, index);
        // avoid cloning an already cloned node, check if there's a duplicate already in sidebar
        // useful on react dev env where the component useEffect is called multiple times
        const existingSidebarDuplicate = document.querySelector(`#sidebar-duplicate-${index}`) as HTMLElement | null;

        if (existingSidebarDuplicate) {
            return;
        }

        const containerNode = document.querySelector('#fix-sidebar-content') as HTMLElement;

        containerNode.appendChild(sidebarDuplicate);
    });
};
